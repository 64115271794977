<template>
    <div class="account">
        <div class="top">
            <span>账户余额</span>
            <p class="money">
                ￥<span>{{ wallet.wallet_balance }}</span>
            </p>
            <p>
                目前为人工提现，每次提现时，请向饭十荟的财务客服人员（微信号：{{configInfo.kefu_wx2}}）发送：“小饭您好，我要提现！”的指令，即可很快到账。
            </p>
            <div class="switch-box">
                <p>
                    1.满{{ wallet.limit_amount }}元可提，一个月可提现{{wallet.limit_number}}次；
                </p>
                <p>2.各银行到账时间不同，请自行留意；</p>
                <p>3.添加客服微信，优先审核到账。</p>
            </div>
            <div class="switch" v-if="false">
                展开<img src="@/assets/images/qiehuan.png" />
            </div>
        </div>

        <div class="nav">
            <router-link to="/apply" class="nav-link">
                <img src="@/assets/images/tg01.png" />
                <span>立即提现</span>
            </router-link>
            <router-link to="/account?from=1" class="nav-link">
                <img src="@/assets/images/tg02.png" />
                <span>我的银行卡</span>
            </router-link>
        </div>
        <!-- 提现列表 -->
        <withdraw-list></withdraw-list>
    </div>
</template>

<script>
    import {
        checkUserWallet
    } from "@/http/api";
    //import WithdrawList from "./withdetails";
    import WithdrawList from "./withdetailsList";
    export default {
        components: {
            WithdrawList
        },
        data() {
            return {
                userToken: "",
                configInfo: {},
                wallet: {}
            };
        },
        mounted() {
            this.userToken = this.$LStorage.getItem("userToken").user_token;
            this.configInfo = this.$LStorage.getItem("curSiteInfo");
            this.checkWallet();
        },
        methods: {
            // 获取账户余额
            async checkWallet() {
                const res = await checkUserWallet({
                    data: {
                        user_token: this.userToken
                    }
                });
                this.wallet = res.data;
            }
        },
    };
</script>

<style lang="scss" scoped>
    .account {
        background: #f6f6f6;
    }

    .account .top {
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.3rem 0.6rem;
    }

    .account .top>span {
        color: #494949;
        font-size: 0.26rem;
        line-height: 3;
    }

    .account .top .money {
        margin-bottom: 15px;
    }

    .account .top p {
        color: #ff8a50;
        font-size: 0.24rem;
    }

    .account .top p span {
        font-size: 0.48rem;
        color: #ff5400;
    }

    .account .top .switch-box {
        width: 100%;
    }

    .account .top .switch {
        color: #282828;
        font-size: 0.26rem;
        display: flex;
        align-items: center;
        padding: 10px 0;
    }

    .account .top .switch img {
        width: 10px;
        margin-left: 5px;
    }

    .account .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4vw 3vw;
    }

    .account .nav .nav-link {
        background: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.28rem;
        height: 18vw;
        width: 46vw;
    }

    .account .nav .nav-link img {
        width: 10vw;
        height: 10vw;
        margin-right: 2vw;
    }

    .account .bot-tab {
        display: flex;
        border-radius: 5px;
        border: 1px solid #ff5400;
        color: #ff5400;
        font-size: 0.32rem;
        width: 73.8vw;
        margin: 0 auto 4vw;
    }

    .account .bot-tab span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25.6vw;
        height: 40px;
    }

    .account .bot-tab span.ac {
        background: #ff5400;
        color: #fff;
    }

    .account .grid {
        border-radius: 5px;
        background: #fff;
        width: 94vw;
        margin: 0 3vw 10px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }

    .account .grid>div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .account .grid span {
        font-size: 0.28rem;
        color: #363636;
    }

    .account .grid .time {
        font-size: 0.2rem;
        color: #a1a1a1;
    }

    .account .grid .money {
        font-size: 0.3rem;
        color: #ff5400;
    }
</style>
